html {
  font-family: "Roboto", Helvetica, sans-serif;
}
body {
  margin: 0;
}

.limit-popover-height {
  max-height: 500px !important;
}

.MuiTableCell-sizeSmall {
  padding: 6px 8px 6px 8px !important;
}

.MuiAutocomplete-popper {
  min-width: 250px !important;
  left: 0px;
}

#searchResultsCountLbl {
  padding-left: 10px;
  font-size: 25px;
}

.searchResultsLbl {
  font-weight: bold;
}